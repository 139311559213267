import React from "react";
import "./index.scss";

import axios from "axios";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import * as backendModule from "../../modules/backendModule";
import useOnScreen from "../../modules/hooks/useOnScreen";
import animateModule from "../../modules/animateModule";

import { FilteredCustomTable } from "../../components/customComponents/Table";
import Spinner from "../../components/customComponents/Spinner";

const Dashboard = () => {
    const [data, setData] = React.useState();
    const [statusData, setStatusData] = React.useState();
    const [data2, setData2] = React.useState();
    const [dateFilters, setDateFilters] = React.useState("danas");

    const [canPaginate, setCanPaginate] = React.useState(false);

    const onScreen = useOnScreen();
    const curNavigate = useNavigate();

    const animateNavigate = to => animateModule(curNavigate, to, document.querySelector(".route__dashboard"));

    const prepareFilters = () => {
        let out = [];

        switch (dateFilters) {
            case "danas":
                out.push({name: "createdAt", op: "deq", value: moment().toDate().getTime()});
                break;
            case "jucer":
                out.push({name: "createdAt", op: "deq", value: moment().add(-1, "days").toDate().getTime()});
                break;
            case "7dana":
                out.push({name: "createdAt", op: "dgeq", value: moment().add(-7, "days").toDate().getTime()});
                break;
            case "30dana":
                out.push({name: "createdAt", op: "dgeq", value: moment().add(-30, "days").toDate().getTime()});
                break;
            case "60dana":
                out.push({name: "createdAt", op: "dgeq", value: moment().add(-60, "days").toDate().getTime()});
                break;
        };

        return out;
    };

    const parseStatus = curStatus => {
        if (!statusData) return "?";
        if (statusData.status !== "ok") return "?";

        for (let key of Object.keys(statusData.data)) {
            if (Number(key) === curStatus) return statusData.data[key];
        };

        return "?";
    };

    const getStatusCodes = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/deals/getDealStatusCodes`,
            ...backendModule.axiosConfig
        }).then(res => {
            setStatusData(res.data);
        }).catch(() => {
            setStatusData(backendModule.genericError);
        });
    };

    const getDealStats = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/deals/getDealStats`,
            data: {
                filters: prepareFilters()
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setData2(res.data);
        }).catch(() => {
            setData2(backendModule.genericError);
        });
    };

    const getData = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/deals/getAllDeals`,
            data: {
                offset: 0,
                limit: 20,
                filters: prepareFilters()
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setData(res.data);
            if (res.data.status === "ok") {
                if (res.data.data.length === 20) {
                    setCanPaginate(true);
                } else {
                    setCanPaginate(false);
                };
            } else {
                setCanPaginate(false);
            };
        }).catch(() => {
            setData(backendModule.genericError);
        });
    };

    const continueData = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/deals/getAllDeals`,
            data: {
                offset: data?.data?.length,
                limit: 20,
                filters: prepareFilters()
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (res.data.data.length === 20) {
                    setCanPaginate(true);
                } else {
                    setCanPaginate(false);
                };
                setData(d => {
                    return {
                        ...d,
                        data: [
                            ...d.data,
                            ...res.data.data
                        ]
                    };
                });
            } else {
                setCanPaginate(false);
            };
        }).catch(() => {
            setData(backendModule.genericError);
        });
    };

    React.useEffect(() => {
        if (onScreen.isIntersecting && canPaginate) {
            setCanPaginate(false);
            continueData();
        };
    }, [onScreen.isIntersecting, canPaginate]);

    React.useEffect(() => {
        getData();
        getDealStats();
    }, [dateFilters]);

    React.useEffect(() => {
        getStatusCodes();
    }, []);

    return <div className="route__dashboard">
        <div className="route__dashboard__filters">
            <p className={dateFilters === "svi" ? "active" : ""} onClick={() => setDateFilters("svi")}>Svi</p>
            <p className={dateFilters === "danas" ? "active" : ""} onClick={() => setDateFilters("danas")}>Danas</p>
            <p className={dateFilters === "jucer" ? "active" : ""} onClick={() => setDateFilters("jucer")}>Jučer</p>
            <p className={dateFilters === "7dana" ? "active" : ""} onClick={() => setDateFilters("7dana")}>7 dana</p>
            <p className={dateFilters === "30dana" ? "active" : ""} onClick={() => setDateFilters("30dana")}>30 dana</p>
            <p className={dateFilters === "60dana" ? "active" : ""} onClick={() => setDateFilters("60dana")}>60 dana</p>

            <div className="route__dashboard__filters__createBtn" onClick={() => animateNavigate("/create")}>Kreiraj narudžbu</div>
        </div>

        <div className="route__dashboard__kpiList">
            <div className="route__dashboard__kpiList__kpi">
                <p className="route__dashboard__kpiList__kpi__name">Ukupno narudžbi</p>
                <p className="route__dashboard__kpiList__kpi__amount">
                    {data2 ? <>
                        {data2.status === "ok" ? data2.data.TotalDeals : "?"}
                    </> : <Spinner color="#0458AD" style={{width: "16px", height: "16px"}} />}
                </p>
            </div>

            <div className="route__dashboard__kpiList__kpi">
                <p className="route__dashboard__kpiList__kpi__name">Isporučene narudžbe</p>
                <p className="route__dashboard__kpiList__kpi__amount">
                    {data2 ? <>
                        {data2.status === "ok" ? data2.data.GoodDeals : "?"}
                    </> : <Spinner color="#0458AD" style={{width: "16px", height: "16px"}} />}
                </p>
            </div>

            <div className="route__dashboard__kpiList__kpi">
                <p className="route__dashboard__kpiList__kpi__name">Na čekanju</p>
                <p className="route__dashboard__kpiList__kpi__amount">
                    {data2 ? <>
                        {data2.status === "ok" ? data2.data.DealsWaiting : "?"}
                    </> : <Spinner color="#0458AD" style={{width: "16px", height: "16px"}} />}
                </p>
            </div>

            <div className="route__dashboard__kpiList__kpi">
                <p className="route__dashboard__kpiList__kpi__name">Neisplaćena zarada</p>
                <p className="route__dashboard__kpiList__kpi__amount">
                    {data2 ? <>
                        {data2.status === "ok" ? `${data2.data.TotalLeftUnpaid} EUR` : "?"}
                    </> : <Spinner color="#0458AD" style={{width: "16px", height: "16px"}} />}
                </p>
            </div>
        </div>

        <FilteredCustomTable
            theme="light"
            accent="#0458AD"
            headers={["ID", "Ime", "Status", "Zarada", "Datum kreiranja"]}
            data={(()=>{
                let out = [];

                if (!data || !statusData) {
                    out.push([{keyID: "noData-spinner", type: "spinner"}]);
                } else {
                    if (data.status === "error" || statusData.status === "error") {
                        out.push([{keyID: "data-error", type: "text", text: "Greška prilikom dohvatanja podataka"}]);
                    } else {
                        if (data.data.length === 0) {
                            out.push([{keyID: "noData-empty", type: "text", text: "Nema podataka za prikaz."}]);
                        } else {
                            for (let item of data.data) {
                                out.push([
                                    {keyID: item.ID, type: "text", text: item.ID},
                                    {keyID: item.ID, type: "text", text: item.FirstLastName},
                                    {keyID: item.ID, type: "text", text: parseStatus(item.Status)},
                                    {keyID: item.ID, type: "text", text: <span style={{color: item.Status === 100 ? "green" : "red"}}>{Number(item.Amount).toFixed(2)} EUR{item.Status !== 100 && ` - nije preuzeto`}</span>},
                                    {keyID: item.ID, type: "text", text: new Date(item.createdAt).toLocaleString()}
                                ]);  
                            };
                        };
                    };
                };

                if (canPaginate) {
                    out.push([{keyID: "data-paginate", type: "custom", data: <p ref={onScreen.measureRef}></p>}])
                };

                return out;
            })()}
        />
    </div>
};

export default Dashboard;