
import React from "react";
import "./index.scss";

const Radio = React.forwardRef((props, ref) => {
    const internalRef = React.useRef();
    React.useImperativeHandle(ref, () => internalRef.current);

    const setActive=(elem)=>{
        if (!internalRef?.current) return;
        if(elem===true){
            internalRef.current.querySelector('.customComponents__radio__yes').classList.add('activeRadio')
            internalRef.current.querySelector('.customComponents__radio__no').classList.remove('activeRadio')
        }else{
            internalRef.current.querySelector('.customComponents__radio__no').classList.add('activeRadio')
            internalRef.current.querySelector('.customComponents__radio__yes').classList.remove('activeRadio')
        }
    }
    React.useEffect(()=>{
        if (!internalRef.current) return;
        if (props.selected !== undefined) setActive(props.selected);
    })
    return <div {...props} className={`customComponents__radio ${props.className ? props.className : ""}`} ref={internalRef}>
            <div onClick={()=>{setActive(true); props.functionYes && props.functionYes()}} className="customComponents__radio__yes activeRadio">
                {props.valueyes}
            </div> 
            <div onClick={()=>{setActive(false); props.functionNo && props.functionNo()}}  className="customComponents__radio__no ">
                {props.valueno}
            </div> 
        
    </div>
});
export default Radio