import React from "react";
import "./index.scss";
import PropTypes from "prop-types";

const Progressbar = (props) => {
    const propColor = props.color ?? "#00A3FF";
    const styles = {};

    const cur = props.value ?? 0;
    const max = props.maximum ?? 100;
    const maxOffset = 100;

    const finalOffset = (maxOffset / max) * cur;

    switch (props.align) {
        case "left": break;
        case "center": {
            styles["margin"] = "0 auto";
            styles["maxWidth"] = "100%";
            styles["width"] = "100%";
            break;
        }
        case "right": {
            styles["left"] = "auto";
        }
        default: break;
    }

    return <svg className="customComponents__progressbar" viewBox="0 0 50 50" style={{...styles, ...props.style}}>
        <circle pathLength={100} style={{
            strokeDashoffset: `${maxOffset - finalOffset}`,
            stroke: propColor
        }} className="customComponents__progressbar__path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
    </svg>
};

Progressbar.propTypes = {
    color: PropTypes.string,
    align: PropTypes.oneOf(["left", "center", "right"]),
    maximum: PropTypes.number,
    value: PropTypes.number
}

export default Progressbar;