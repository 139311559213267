import React from "react";
import "./index.scss";

import { useNavigate } from "react-router-dom";
import axios from "axios";
import * as backendModule from "../../modules/backendModule";
import animateModule from "../../modules/animateModule";

import Spinner from "../../components/customComponents/Spinner";
import CustomInput from "../../components/customComponents/CustomInput";
import Dropdown from "../../components/customComponents/Dropdown";

const CreateDeal = () => {
    const [spinner, setSpinner] = React.useState(false);
    const [infoP, setInfoP] = React.useState("");

    const [countries, setCountries] = React.useState();
    const [selectedCountry, setSelectedCountry] = React.useState();

    const [cities, setCities] = React.useState();
    const [selectedCity, setSelectedCity] = React.useState();

    const [selectedGender, setSelectedGender] = React.useState(null);

    const [articles, setArticles] = React.useState();
    const [selectedArticles, setSelectedArticles] = React.useState([]);

    const resetArticleDropdownRef = React.useRef(() => null);

    const firstLastNameRef = React.useRef();
    const phoneNumberRef = React.useRef();
    const addressRef = React.useRef();
    const houseNumberRef = React.useRef();
    const birthYearRef = React.useRef();

    const curNavigate = useNavigate();

    const animateNavigate = to => animateModule(curNavigate, to, document.querySelector(".component__contentWrapper"));

    const getCountries = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/data/countries`,
            ...backendModule.axiosConfig
        }).then(res => {
            setCountries(res.data);
        }).catch(() => {
            setCountries(backendModule.genericError);
        });
    };

    const getCities = () => {
        if (countries?.status !== "ok") return;
        let curCountry = countries.data.find(c => c.ID === selectedCountry);
        if (!curCountry) return;

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/data/cities/${curCountry.CountryCode}`,
            ...backendModule.axiosConfig
        }).then(res => {
            setCities(res.data);
        }).catch(() => {
            setCities(backendModule.genericError);
        });
    };

    const getArticles = () => {
        if (countries?.status !== "ok") return;
        let curCountry = countries.data.find(c => c.ID === selectedCountry);
        if (!curCountry) return;

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/data/articles/${curCountry.ID}`,
            ...backendModule.axiosConfig
        }).then(res => {
            setArticles(res.data);
        }).catch(() => {
            setArticles(backendModule.genericError);
        });
    };

    const createOrder = () => {
        if (spinner) return;
        setInfoP("");
        if (countries?.status !== "ok") return null;
        let curCountry = countries.data.find(c => c.ID === selectedCountry);
        if (!curCountry) return null;
                                
        const data = {
            FirstLastName: firstLastNameRef.current.value,
            PhoneNumber: phoneNumberRef.current.value,
            Address: addressRef.current.value,
            Country: curCountry.CountryCode,
            City: selectedCity,
            HouseNumber: houseNumberRef.current.value,
            Articles: selectedArticles.map(art => {
                return {data: {id: art, quantity: 1}}
            }),
            Gender: !!selectedGender,
            BirthYear: Number(birthYearRef.current.value)
        };

        if (!data.FirstLastName) return setInfoP("Ime ne može biti prazno");
        if (!data.PhoneNumber) return setInfoP("Broj telefona ne može biti prazan");
        if (!data.Address) return setInfoP("Adresa ne može biti prazna");
        if (!data.Country) return setInfoP("Država ne može biti prazna");
        if (data.City === null || data.City === undefined) return setInfoP("Grad ne može biti prazan");
        if (!data.HouseNumber) return setInfoP("Broj kuće ne može biti prazan, koristite 'bb'");
        if (data.Articles.length === 0) return setInfoP("Unesite barem jedan artikal");
        if (isNaN(data.BirthYear)) return setInfoP("Godište mora biti broj");
        if (data.BirthYear < 1700 || data.BirthYear > 3000) return setInfoP("Godište nije validno");

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/deals/addDeal`,
            data,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                animateNavigate("/dashboard");
            } else {
                setInfoP("Greška prilikom kreiranja narudžbe. Provjerite informacije i pokušajte ponovo.");
            };
        }).catch(() => {
            setInfoP("Server ne reagira");
        }).finally(() => {
            setSpinner(false);
        });
    };

    React.useEffect(() => {
        if (selectedCountry) getCities();
        getArticles();
    }, [selectedCountry]);

    React.useEffect(() => {
        getCountries();
    }, []);

    return <div className="route__createDeal">
        <div className="route__createDeal__wrap">
            <div className="route__createDeal__wrap__element">
                <p>Država</p>
                {countries ? <Dropdown
                    theme="light"
                    accent="#0458AD"
                    style={{height: "35px"}}
                    data={(()=>{
                        if (countries.status !== "ok") return [];
                        return countries.data.map(c => {
                            return {name: c.CountryName, value: c.ID}
                        });
                    })()}
                    onChange={e => {
                        setSelectedCity();
                        setCities();
                        setSelectedArticles([]);
                        setArticles([]);
                        setSelectedCountry(e?.value);
                    }}
                    selected={(()=>{
                        if (countries?.status !== "ok") return null;
                        return countries.data.indexOf(countries.data.map(c => c.ID === selectedCountry));
                    })()}
                /> : <Spinner color="#0458AD" style={{width: "32px", height: "32px"}} />}
            </div>

            {selectedCountry && <div className="route__createDeal__wrap__element">
                <p>Grad</p>
                {cities ? <Dropdown
                    theme="light"
                    accent="#0458AD"
                    style={{height: "35px"}}
                    data={(()=>{
                        if (cities.status !== "ok") return [];
                        return cities.data.map(c => {
                            return {name: c.Name, value: c.ID}
                        });
                    })()}
                    onChange={e => setSelectedCity(e?.value)}
                    selected={(()=>{
                        if (cities?.status !== "ok") return null;
                        return cities.data.indexOf(cities.data.map(c => c.ID === selectedCity));
                    })()}
                /> : <Spinner color="#0458AD" style={{width: "32px", height: "32px"}} />}
            </div>}

            {selectedCity && <>
                <div className="route__createDeal__wrap__element">
                    <p></p>
                    <CustomInput
                        accent="#0458AD"
                        placeholder="Ime i prezime"
                        ref={firstLastNameRef}
                    />
                </div>

                <div className="route__createDeal__wrap__element">
                    <p></p>
                    <CustomInput
                        accent="#0458AD"
                        placeholder="Broj telefona sa pozivnim (+387, +381...)"
                        ref={phoneNumberRef}
                    />
                </div>

                <div className="route__createDeal__wrap__element">
                    <p></p>
                    <CustomInput
                        accent="#0458AD"
                        placeholder="Adresa"
                        ref={addressRef}
                    />
                </div>

                <div className="route__createDeal__wrap__element">
                    <p></p>
                    <CustomInput
                        accent="#0458AD"
                        placeholder="Broj kuće / stana (ili 'bb' ako nema)"
                        ref={houseNumberRef}
                    />
                </div>

                <div className="route__createDeal__wrap__element">
                    <p></p>
                    <CustomInput
                        accent="#0458AD"
                        placeholder="Godište (na primjer 1980)"
                        ref={birthYearRef}
                    />
                </div>

                <div className="route__createDeal__wrap__element">
                    <p>Spol</p>
                    <Dropdown
                        theme="light"
                        accent="#0458AD"
                        style={{height: "35px"}}
                        data={[
                            {name: "Muški", value: true},
                            {name: "Ženski", value: false}
                        ]}
                        onChange={e => setSelectedGender(e?.value)}
                        selected={(()=>{
                            if (selectedGender === null) return null;
                            return selectedGender ? 0 : 1;
                        })()}
                    />
                </div>

                <div className="route__createDeal__wrap__element">
                    <p>Artikli</p>
                    {articles ? <>

                        {selectedArticles.length > 0 && <div className="route__createDeal__wrap__element__articles">
                            {(()=>{
                                if (countries?.status !== "ok") return null;
                                let curCountry = countries.data.find(c => c.ID === selectedCountry);
                                if (!curCountry) return null;

                                return selectedArticles.map((art, artIdx) => {
                                    if (!articles) return null;
                                    if (articles.status !== "ok") return null;

                                    let curArticle = articles.data.find(a => a.ID === art);
                                    if (!curArticle) curArticle = {ArticleName: "?", ArticlePrice: "?"}

                                    return <>
                                        <div className="route__createDeal__wrap__element__articles__btn" onClick={() => {
                                            setSelectedArticles(sa => sa.filter((_, saIdx) => saIdx !== artIdx));
                                        }}>Obriši</div>
                                        <p>{`${curArticle.ArticleName} ${Number(curArticle.ArticlePrice).toFixed(2)} ${curCountry.CountryCurrency}`}</p>
                                    </>
                                });
                            })()}
                        </div>}

                        <Dropdown
                            heme="light"
                            accent="#0458AD"
                            style={{height: "35px"}}
                            data={(()=>{
                                if (countries?.status !== "ok") return [];
                                let curCountry = countries.data.find(c => c.ID === selectedCountry);
                                if (!curCountry) return [];

                                return (articles.status === "ok" ? articles.data : []).map(art => {
                                    return {value: art.ID, name: `${art.ArticleName} ${Number(art.ArticlePrice).toFixed(2)} ${curCountry.CountryCurrency}`};
                                });
                            })()}
                            onChange={e => {
                                resetArticleDropdownRef.current();

                                setSelectedArticles(art => [...art, e?.value]);
                            }}
                            onReset={r => resetArticleDropdownRef.current = r}
                        />
                    </> : <Spinner color="#0458AD" style={{width: "32px", height: "32px"}} />}
                </div>
            </>}

            {(selectedArticles.length > 0 && selectedCity && selectedCountry && selectedGender !== null) && <div className="route__createDeal__wrap__finalBtn" onClick={createOrder}>
                {spinner ? <Spinner style={{width: "24px", height: "24px"}} color="white"  /> : "Kreiraj narudžbu"}
            </div>}
            {infoP && <p className="route__createDeal__wrap__infoP">{infoP}</p>}
        </div>
    </div>
};

export default CreateDeal;