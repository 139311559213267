import React from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";

import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import animateModule from "./modules/animateModule";
import * as backendModule from "./modules/backendModule";
import * as userDataActions from "./actions/userDataActions";
import * as timestmapActions from "./actions/timestampActions";

import Header from "./components/Header";
import Spinner from "./components/customComponents/Spinner";

import Login from "./routes/Login";
import ContentWrapper from "./components/ContentWrapper";
import Users from "./routes/Users";
import Dashboard from "./routes/Dashboard";
import CreateDeal from "./routes/CreateDeal";

let loginTimeout = null;
const App = () => {
  const userDataSelector = useSelector(state => state?.userData ?? {});
  const timestampSelector = useSelector(state => state?.timestamp ?? null);

  const mainDispatch = useDispatch();
  const mainNavigate = useNavigate();
  const curLocation = useLocation();

  const animateNavigate = to => {
    animateModule(mainNavigate, to, document.querySelector(".component__contentWrapper"));
  };

  const checkLogin = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/auth/checkLogin`,
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        mainDispatch(userDataActions.setUserData(true, res.data.data));
        if (window.location.toString().endsWith("/login")) animateNavigate("/");

        loginTimeout = setTimeout(checkLogin, 20000);
      } else {
        animateNavigate("/login");
        mainDispatch(userDataActions.setUserData(false, null));
      };
    }).catch(() => {
      mainDispatch(userDataActions.setUserData(null, null));
      setTimeout(() => mainDispatch(timestmapActions.updateTimestamp()), 2000);

      loginTimeout = setTimeout(checkLogin, 2000);
    });
  };
  
  React.useEffect(() => {
    checkLogin();

    return () => clearTimeout(loginTimeout);
  }, [timestampSelector]);

  React.useEffect(() => {
    if (userDataSelector.isLoggedIn) {
      if (!curLocation.pathname.startsWith("/users") && !curLocation.pathname.startsWith("/dashboard") && !curLocation.pathname.startsWith("/create")) {
        mainNavigate("/dashboard");
      };
    };
  }, [curLocation.pathname, userDataSelector?.isLoggedIn]);

  React.useEffect(() => {
    let handler = (e) => {
      if (
        e?.oldURL.endsWith("/login") ||
        e?.newURL.endsWith("/login")
      ) setTimeout(() => mainDispatch(timestmapActions.updateTimestamp()), 0);
    };

    window.addEventListener("hashchange", handler);

    return () => window.removeEventListener("hashchange", handler);
  }, []);


  return userDataSelector.isLoggedIn === null ? <div className="root__init">
    <Spinner color="#FCA311" />
  </div> : (userDataSelector.isLoggedIn ? <>
    <Header />
    <ContentWrapper style={{ backgroundColor: "#EEEEEE" }}>
      <Routes>

      <Route path="/users" element={<Users />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/create" element={<CreateDeal />} />

      </Routes>
    </ContentWrapper>
  </> : <>
    <ContentWrapper style={{ gridRow: "1 / span 2", gridColumn: "1 / span 2" }}>
      <Routes>

        <Route path="/login" element={<Login />} />
        
      </Routes>
    </ContentWrapper>
  </>)
};

export default App;
