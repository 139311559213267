import "./index.scss";

import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import animateModule from "../../modules/animateModule";
import * as backendModule from "../../modules/backendModule";
import { updateTimestamp } from "../../actions/timestampActions";

const Header = () => {
    const curNavigate = useNavigate();
    const curLocation = useLocation();
    const curDispatch = useDispatch();
    const isAdmin = useSelector(state => state?.userData.userData.UserInfo.Flags.isAdmin ?? false);

    const animateNavigate = to => {
        animateModule(curNavigate, to, document.querySelector(".component__contentWrapper"));
    };

    const logout = () => {
        axios({
            method: "GET",
            url: `${backendModule.backendURL}/auth/logout`,
            ...backendModule.axiosConfig
        }).then(() => null).catch(() => null).finally(() => {
            curDispatch(updateTimestamp());
            animateNavigate("/");
        });
    };

    return <div className="component__header">
        <div className="component__header__leftSide">
            <img alt="logo" src="/images/logo.png" className="component__header__leftSide__logo" />
            <p onClick={()=>{
                animateNavigate("/dashboard")
            }} className={`component__header__leftSide__menu ${curLocation.pathname === "/dashboard" ? "component__header__leftSide__menu--active" : ""}`}>Dashboard</p>
            {isAdmin &&<p onClick={()=>{
                animateNavigate("/users")
            }} className={`component__header__leftSide__menu ${curLocation.pathname === "/users" ? "component__header__leftSide__menu--active" : ""}`}>Korisnici</p>}
        </div>

        <div className="component__header__rightSide">
            <p className="component__header__rightSide__menu" onClick={logout}>Logout</p>
        </div>

    </div>
};

export default Header;