import React from "react";
import "./index.scss";

import { useSelector } from "react-redux";
import axios from "axios";
import * as backendModule from "../../modules/backendModule";

import { FilteredCustomTable } from "../../components/customComponents/Table";
import Button from "../../components/customComponents/Button";
import CustomInput from "../../components/customComponents/CustomInput";
import Spinner from "../../components/customComponents/Spinner";
import CustomRadio from "../../components/customComponents/Radio";

const Users = () => {
    const [data, setData] = React.useState();
    const [filters, setFilters] = React.useState([]);
    const [add, setAdd] = React.useState(false);
    const [hasEarnings, setHasEarnings] = React.useState();

    const usersSelector = useSelector(state => state?.userData ?? {});

    const getData = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/getAllUsers`,
            data: {
                filters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setData(res.data);
        }).catch(() => {
            setData(backendModule.genericError);
        });
    };

    React.useEffect(() => {
        getData();
    }, [filters]);

    if (!usersSelector.isLoggedIn) return null;
    return <div className="route__users">
        <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '20px'
        }}>
            <Button value={'Add user'} onClick={() => {
                setAdd(!add)
            }} />
        </div>
        <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '20px',
            gap: '20px'
        }}>
            <Button value={'Svi korisnici'} style={{ background: !hasEarnings ? 'white' : "#00A3FF", border: '1px solid #00A3FF', color: !hasEarnings ? "#00A3FF" : 'white' }} onClick={() => {
                setHasEarnings(false)
            }} />
            <Button value={'Korisnici sa zaradom'} style={{ background: hasEarnings ? 'white' : "#00A3FF", border: '1px solid #00A3FF', color: hasEarnings ? "#00A3FF" : 'white' }} onClick={() => {
                setHasEarnings(true)
            }} />
        </div>
        {add && <AddUser update={getData} close={setAdd} />}
        <FilteredCustomTable
            accent={"#0458AD"}
            headers={["ID", "Username", "Email", "Admin", "Zarada"]}
            filters={[
                { name: "ID", friendlyName: "ID", type: "string" },
                { name: "Username", friendlyName: "Username", type: "string" },
                { name: "Email", friendlyName: "Email", type: "string" },
                { name: "Flags:isAdmin", friendlyName: "Role: Admin", type: "string" },
            ]}
            filterCB={data => setFilters(data)}
            data={(() => {
                let out = [];

                if (data) {
                    if (data.status === "ok") {
                        for (let [index, item] of data.data.entries()) {
                            if (!hasEarnings) {
                                out.push([
                                    { keyID: item.ID, type: "text", text: item.ID },
                                    { keyID: item.ID, type: "text", text: item.Username },
                                    { keyID: item.ID, type: "text", text: item.Email },
                                    { keyID: item.ID, type: "text", text: item.Flags?.isAdmin ? <span style={{ color: "green" }}>Da</span> : <span style={{ color: "red" }}>Ne</span> },
                                    { keyID: item.ID, type: "text", text: `${item.Earnings} €` },
                                    {
                                        keyID: item.ID, type: "groupNewline", group: [
                                            {
                                                keyID: item.ID, type: "button", text: "Edit", triggerDropdown: true, triggerData: c => {
                                                    return <EditUser ID={item.ID} user={item} c={c} update={getData} />
                                                }
                                            },
                                            item.Earnings > 0 && {
                                                keyID: item.ID, type: "button", text: "Pay", triggerDropdown: true, triggerData: c => {
                                                    return <PayUser ID={item.ID} user={item} c={c} update={getData} />
                                                }
                                            }
                                        ]
                                    }

                                ]);
                            }
                            else if(item.Earnings>0){
                                out.push([
                                    { keyID: item.ID, type: "text", text: item.ID },
                                    { keyID: item.ID, type: "text", text: item.Username },
                                    { keyID: item.ID, type: "text", text: item.Email },
                                    { keyID: item.ID, type: "text", text: item.Flags?.isAdmin ? <span style={{ color: "green" }}>Da</span> : <span style={{ color: "red" }}>Ne</span> },
                                    { keyID: item.ID, type: "text", text: `${item.Earnings} €` },
                                    {
                                        keyID: item.ID, type: "groupNewline", group: [
                                            {
                                                keyID: item.ID, type: "button", text: "Edit", triggerDropdown: true, triggerData: c => {
                                                    return <EditUser ID={item.ID} user={item} c={c} update={getData} />
                                                }
                                            },
                                            item.Earnings > 0 && {
                                                keyID: item.ID, type: "button", text: "Pay", triggerDropdown: true, triggerData: c => {
                                                    return <PayUser ID={item.ID} user={item} c={c} update={getData} />
                                                }
                                            }
                                        ]
                                    }

                                ]);
                            }
                        };

                        if (out.length === 0) {
                            out.push([{ keyID: "noData-noData", type: "text", text: "Nema korisnika za prikaz." }]);
                        };
                    } else {
                        out.push([{ keyID: "data-error", type: "text", text: "Došlo je do greške." }]);
                    };
                } else {
                    out.push([{ keyID: "noData-spinner", type: "spinner" }]);
                };
                return out;
            })()}
        />

    </div>
};

export default Users;


const EditUser = (props) => {
    let [spinner, setSpinner] = React.useState(false);
    let [finalData, setFinalData] = React.useState(null);
    let [oldData, setOldData] = React.useState({});
    let [fAdmin, setFAdmin] = React.useState(props.user.Flags.isAdmin);

    let usernameRef = React.useRef();
    let emailRef = React.useRef();
    let passwordRef = React.useRef();

    const getData = () => {
        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/getUserByID`,
            data: {
                ID: props.ID
            },
            ...backendModule.axiosConfig
        }).then(async res => {
            if (res.data.status === "ok") {
                setSpinner(false);
                setOldData(res.data.data);
            } else {
                setFinalData("Failed to get user data!");
            };
        }).catch((e) => {
            setFinalData("There was an error while connecting to the server!");
        }).finally(() => {
            setSpinner(false);
        });
    };
    React.useEffect(() => {
        getData();
    }, [])


    const performVerify = () => {
        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/editUser`,
            data: {
                id: props.ID,
                newUsername: usernameRef.current.value,
                newEmail: emailRef.current.value,
                newFlags: JSON.stringify({ isAdmin: fAdmin }),
                newPassword: passwordRef.current.value ? passwordRef.current.value : null
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                axios({
                    method: "POST",
                    url: `${backendModule.backendURL}/users/getUserByID`,
                    data: {
                        ID: props.ID
                    },
                    ...backendModule.axiosConfig
                }).then(final => {
                    if (final.status === "error") {
                        setFinalData("There was an error while getting the new user!");
                        setSpinner(false);
                    } else {
                        props.update();
                        setSpinner(false);
                        setTimeout(() => {
                            props.c();
                        }, 100)
                    }
                }).catch(() => {
                    setFinalData("There was an error while connecting to the server!");
                    setSpinner(false);
                });
            } else {
                setFinalData("Failed to edit user!");
                setSpinner(false);
            }
        }).catch(() => {
            setFinalData("There was an error while connecting to the server!");
            setSpinner(false);
        });
    };


    if (spinner) return <Spinner />
    if (finalData) {
        return <div className="route__editUser__verifyPublisher">
            <p>{finalData}</p>
            <Button value="Close" onClick={e => {
                props.c().then(() => {
                    if (props.onUpdate) props.onUpdate(props.user);
                });
            }} />
        </div>
    }
    return <div className="route__editUser__addUser">
        <div className="route__editUser__addUser__userInfo" >
            <CustomInput placeholder="Username" ref={usernameRef} value={oldData.Username} />
            <CustomInput placeholder="Email" ref={emailRef} value={oldData.Email} />
            <CustomInput placeholder="Password" ref={passwordRef} />
            <p>Admin <CustomRadio selected={fAdmin} className="route__adminUsers__addUser__userInfo__flags__radio" valueyes={<p>Yes</p>} valueno={<p>No</p>} functionYes={() => setFAdmin(true)} functionNo={() => setFAdmin(false)} /></p>

        </div>
        <Button value="Save" onClick={performVerify} style={{ backgroundColor: "#00A3FF", color: 'white', marginRight: "10px" }} />
        <Button value="Cancel" onClick={props.c} />
    </div>
}

const AddUser = (props) => {
    let [spinner, setSpinner] = React.useState(false);
    let [finalData, setFinalData] = React.useState(null);
    let [fAdmin, setFAdmin] = React.useState();

    let usernameRef = React.useRef();
    let emailRef = React.useRef();
    const passwordRef = React.useRef();



    const performVerify = () => {
        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/createUser`,
            data: {
                username: usernameRef.current.value,
                email: emailRef.current.value,
                flags: { isAdmin: fAdmin },
                password: passwordRef.current.value
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                props.update();
                setSpinner(false);
                props.close(false);
            } else {
                setFinalData("Failed to edit user!");
                setSpinner(false);
            }
        }).catch(() => {
            setFinalData("There was an error while connecting to the server!");
            setSpinner(false);
        });
    };


    if (spinner) return <Spinner />
    if (finalData) {
        return <div className="route__editUser__verifyPublisher">
            <p>{finalData}</p>
            <Button value="Close" onClick={e => {
                props.c().then(() => {
                    if (props.onUpdate) props.onUpdate(props.user);
                });
            }} />
        </div>
    }
    return <div className="route__editUser__addUser">
        <div className="route__editUser__addUser__userInfo" >
            <CustomInput placeholder="Username" ref={usernameRef} />
            <CustomInput placeholder="Email" ref={emailRef} />
            <CustomInput placeholder="Password" ref={passwordRef} />
            <p>Admin <CustomRadio selected={fAdmin} className="route__adminUsers__addUser__userInfo__flags__radio" valueyes={<p>Yes</p>} valueno={<p>No</p>} functionYes={() => setFAdmin(true)} functionNo={() => setFAdmin(false)} /></p>

        </div>
        <Button value="Save" onClick={performVerify} style={{ backgroundColor: "#00A3FF", color: 'white', marginRight: "10px" }} />
        <Button value="Cancel" onClick={() => {
            props.close(false);
        }} />
    </div>
}


const PayUser = (props) => {
    const [finalData, setFinalData] = React.useState()



    const performVerify = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/deals/clearClientEarnings`,
            data: {
                UserID: props.ID

            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                props.update();
                props.c();
            } else {
                setFinalData("Failed to edit user!");
            }
        }).catch(() => {
            setFinalData("There was an error while connecting to the server!");
        });
    };



    return <div className="route__editUser__addUser">
        <p>Jeste li sigurni da zelite izvrsiti payment?</p>
        <Button value="Save" onClick={performVerify} style={{ backgroundColor: "#00A3FF", color: 'white', marginRight: "10px" }} />
        <Button value="Cancel" onClick={props.c} />
    </div>
}