import React from "react";
import "./index.scss";

import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as backendModule from "../../modules/backendModule";
import animateModule from "../../modules/animateModule";
import { updateTimestamp } from "../../actions/timestampActions";

import Spinner from "../../components/customComponents/Spinner";

const Login = () => {
    const [spinner, setSpinner] = React.useState(false);
    const [infoP, setInfoP] = React.useState("");

    const usernameRef = React.useRef();
    const passwordRef = React.useRef();

    const curDispatch = useDispatch();
    const curNavigate = useNavigate();

    const animateNavigate = to => animateModule(curNavigate, to, document.querySelector("#root"));

    const login = () => {
        if (spinner) return;
        setInfoP("");

        const data = {
            username: usernameRef.current.value,
            password: passwordRef.current.value
        };

        if (!data.username || !data.password) return setInfoP("Molimo unesite Vaše podatke.");

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/auth/login`,
            data,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                curDispatch(updateTimestamp());
                animateNavigate("/");
            } else {
                return setInfoP("Ime ili lozinka nisu tačni.");
            };
        }).catch(() => {
            return setInfoP("Server ne reagira!");
        }).finally(() => {
            setSpinner(false);
        });
    };

    return <div className="route__login">
        <div className="route__login__wrap">
            <img src="/images/logo.png" />

            <div className="route__login__wrap__input">
                <span>Korisničko ime</span>
                <input ref={usernameRef} type="text" placeholder="Korisničko ime" />
            </div>

            <div className="route__login__wrap__input">
                <span>Lozinka</span>
                <input ref={passwordRef} type="password" placeholder="Lozinka" />
            </div>

            <div className="route__login__wrap__button" style={{
                pointerEvents: spinner ? "none" : "all"
            }} onClick={login}>
                {spinner ? <Spinner style={{width: "32px", height: "32px"}} color="white" /> : "Prijava"}
            </div>

            {infoP && <p className="route__login__wrap__infoP">{infoP}</p>}
        </div>
    </div>
};

export default Login;