import React, { useCallback, useState } from "react";

/**
 * Wrapper hook for implementing infinite scrolls
 * @param {Object} arg
 * @param {HTMLElement} arg.root Node to be checked against
 * @param {string} arg.rootMargin Margin of the root element
 * @param {number} arg.treshold Intrsection treshold (0.5=half, 1=all, 0=none...)
 * @returns {{
 * measureRef: React.MutableRefObject,
 * isIntersecting: boolean,
 * observer: IntersectionObserver
 * }}
 */
const useOnScreen = ({
  root = null,
  rootMargin = "0px",
  threshold = 0
} = {}) => {
  const [observer, setOserver] = useState();
  const [isIntersecting, setIntersecting] = useState(false);

  const measureRef = useCallback(
    (node) => {
      if (node) {
        const observer = new IntersectionObserver(
          ([entry]) => {
            setIntersecting(entry.isIntersecting);
          },
          { root, rootMargin, threshold }
        );

        observer.observe(node);
        setOserver(observer);
      }
    },
    [root, rootMargin, threshold]
  );

  return { measureRef, isIntersecting, observer };
};

export default useOnScreen;
