const initialState = {
    isLoggedIn: null,
    userData: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case "USERDATA_UPDATE": {
            return {...state, isLoggedIn: action.payload.isLoggedIn, userData: action.payload.userData};
        };
        default: return state;
    };
};