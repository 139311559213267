import React from 'react';
import { createRoot } from "react-dom/client";
import './index.css';

import { HashRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import allReducers from "./reducers";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";

import * as backendModule from "./modules/backendModule";


import App from './App';

const store = createStore(allReducers, composeWithDevTools());

backendModule.setAxiosDataHandler();
backendModule.setStore(store);

const rootElem = createRoot(document.querySelector("#root"));
rootElem.render(<Provider store={store}>
    <Router>
        <App />
    </Router>
</Provider>);