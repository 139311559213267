import React from "react";
import "./index.scss";
import PropTypes from "prop-types";

const Button = React.forwardRef((props, ref) => {
    return <button className={`customComponents__buttonSmall ${props.className ? props.className : ""}`} style={{
        ...props.style ?? {},
        backgroundColor: props.accent ?? (props.style?.backgroundColor ?? null)
    }} onClick={props.onClick}>
        {props.value}
    </button>
});

Button.propTypes = {
    value: PropTypes.string,
    accent: PropTypes.string,
}

export default Button